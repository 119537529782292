<template>
  <div class="form-login">
    <google-login-btn />
  </div>
</template>
<script>
import NativeBridge from '@/common/nativebridge';
import GoogleLoginBtn from '@/components/forms/GoogleLoginBtn.vue';

export default {
  name: 'LoginForm',
  components: {
    GoogleLoginBtn,
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },

  methods: {
    executeLogin() {
      const userData = { email: this.email, password: this.password };
      this.$emit('submited', userData);
    },

    login() {
      NativeBridge.call('googleSignIn', {}, this.googleSignInCallback);
    },

  },
};
</script>

<style lang="scss" scoped>
</style>
