<template>
  <div class="login-view">
    <v-card
      class="login-card"
      width="440"
      rounded="lg"
    >
      <v-card-title class="login-title">
        <v-img
          class="login-logo"
          src="@/assets/img/retrologo.png"
        />
      </v-card-title>

      <v-card-subtitle class="login-subtitle">
        {{ $t('views.login.welcome') }}
        <span class="subtitle-green">
          {{ $t('views.login.viking') }}
        </span>
      </v-card-subtitle>

      <div class="form-login-container">
        <login-form @submited="doLogin" />
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginForm from '@/components/forms/login/LoginForm.vue';

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'hasSession',
    ]),
  },
  methods: {
    doLogin(params) {
      this.$store.dispatch('initLoading');
      this.$store.dispatch('login', params);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/forms/login.scss" scoped/>
